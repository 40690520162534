<template>

<router-link class="tag" :to="{name: 'News', query: { topic: item.slug }}">{{ item.name }} <span>{{ item.count }}</span><i class="fa-solid fa-chevron-right"></i></router-link>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.tag {
	display: block;
	font-size: 16px;
	line-height: 28px;
	font-weight: 700;
	padding: 14px 68px 14px 20px;
	color: #45464B;
	border-bottom: 1px solid #F5F5F5;
}

.is-desktop .tag:hover {
	background-color: #485CC7;
	color: #fff;
}

.is-mobile .tag {
	font-size: 14px;
	line-height: 20px;
	padding: 14px 48px 14px 0px;
}

.tag span {
	position: absolute;
	top: 15px;
	right: 46px;
	color: #ddd;
	letter-spacing: 1.4px;
}

.is-mobile .tag span {
	right: 26px;
}

.tag i {
	position: absolute;
	top: 21px;
	right: 20px;
	color: #FF2D07;
	font-size: 14px;
}

.is-mobile .tag i {
	right: 0px;
	top: 19px;
}

</style>
