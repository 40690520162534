import '@/assets/reset.css'

import Vue from 'vue'
import App from './App.vue'

import _ from 'underscore'
import moment from 'moment'
import Vue2TouchEvents from 'vue2-touch-events'

import constants from './constants'
import router from './router'
import store from './store'
import pubsub from './pubsub'
import api from './api'
import util from './util'

import mixInjection from './mixin/injection'
import mixShortcuts from './mixin/shortcuts'
import mixWindow from './mixin/window'

Vue.config.productionTip = false

var requireComponentGlobal = require.context('./components/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

Vue.use(Vue2TouchEvents)

Vue.mixin(mixWindow)
Vue.mixin(mixInjection)
Vue.mixin(mixShortcuts)

Vue.filter('capitalise', function (value) {

	return value.charAt(0).toUpperCase() + value.slice(1)

})

Vue.filter('prettyNumber', function (value) {

	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

})

Vue.filter('round', function (value, decimals, force) {

	force = force || false
	value = parseFloat(value)

	return ((value % 1 !== 0 || force) && value !== undefined) ? value.toFixed(decimals) : value

})

Vue.filter('nth', function (value) {

	var remainder = value % 10

	if (remainder === 1 && value !== 11) return value.toString() + 'st'
	if (remainder === 2 && value !== 12) return value.toString() + 'nd'
	if (remainder === 3 && value !== 13) return value.toString() + 'rd'

	return value.toString() + 'th'

})

Vue.filter('fromNow', function (value) {

	return (value) ? moment.unix(value).local().fromNow() : ''

})

Vue.filter('daysSince', function (value) {

	return (value) ? moment().utc().diff(moment.unix(value).utc(), 'days') : ''

})

Vue.filter('daysBetween', function (from, to) {

	return (from) ? Math.abs(moment.unix(to).utc().diff(moment.unix(from).utc(), 'days')) : ''

})

Vue.filter('formatDate', function (value, format) {

	return (value) ? moment.unix(value).utc().format(format) : ''

})

Vue.directive('bgimage', {

	bind: function (el, binding) {

		if (binding.value !== undefined) {

			var file = (binding.value.indexOf('data:') === 0 || binding.value.indexOf('https:') === 0) ? binding.value : store.getters['config'].base + binding.value

			el.style.backgroundImage = (binding.value) ? 'url(' + file + ')' : false

		}

	},

	update: function (el, binding) {

		if (binding.value !== undefined) {

			var file = (binding.value.indexOf('data:') === 0 || binding.value.indexOf('https:') === 0) ? binding.value : store.getters['config'].base + binding.value

			el.style.backgroundImage = (binding.value) ? 'url(' + file + ')' : false

		}

	}

})

new Vue({
	_,
	router,
	constants,
	util,
	store,
	moment,
	api,
	pubsub,
	render: h => h(App),
	created: function() {

		pubsub.$emit('window.initiated')

	}
}).$mount('#app')
