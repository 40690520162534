<template>

<router-link :data-theme="theme" :to="to || {}" class="button">{{ text }}</router-link>

</template>

<script>

export default {

	props: ['to', 'theme', 'text']

}

</script>

<style scoped>

.button {
	color: #fff;
	background-color: #1A2046;
	padding: 0px 20px;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
	font-weight: 500;
	border-radius: 26px;
	width: 170px;
	text-align: center;
	margin: 0px auto;
	display: block;
}

.is-mobile .button {
	height: 40px;
	width: 100%;
	line-height: 40px;
	font-size: 14px;
}

.button:hover {
	background-color: #111632;
}

.button[data-theme="blue"] {
	background-color: #485CC7;
}

.button[data-theme="blue"]:hover {
	background-color: #34459f;
}

</style>
