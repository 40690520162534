<template>

<app-wrapper class="quotes" v-touch:swipe.right="onBackClick" v-touch:swipe.left="onForwardClick">
	
	<app-wrapper class="quotes-content" :content="true">

		<h2>What people say</h2>

		<app-wrapper>

			<app-wrapper v-for="(item, index) in items" :key="index" class="quotes-item" :class="{'is-active': index === active, 'is-left': index < active, 'is-right': index > active}">

				<p v-html="item.text" />

				<small><b>{{ item.name }}</b></small>

			</app-wrapper>

		</app-wrapper>

		<div class="quotes-pips" v-if="items.length > 1">
			<div v-for="(item, index) in items" :key="index" class="quotes-pips-item" :class="{'is-active': index === active}" v-on:click="onJumpClick(index)" />
		</div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

import mixPips from '@/mixin/pips.js'

export default {

	mixins: [mixPips],

	computed: {

		items: function() {

			return this.$state.quotes

		}

	}

}

</script>

<style scoped>

.quotes {
	background-color: #00A3AC;
	padding: 100px 0px;
	margin-bottom: 100px;
	background-image: url(~@/assets/quotes.png);
	background-position: calc(50% - 60px) 50%;
	background-size: cover;
}

.is-tablet .quotes {
	padding: 48px 0px;
	margin-bottom: 40px;
	background-position: calc(50% - 100px) 50%;
}

.is-mobile .quotes {
	padding: 32px 0px;
	margin-bottom: 0px;
	background-image: none;
}

.quotes:before {
	content: '';
	display: block;
	background-image: url(~@/assets/quotes-woman.png);
	position: absolute;
	right: 50%;
	bottom: -100px;
	width: 1087px;
	height: 700px;
	background-size: contain;
	background-position: 100% 100%;
	background-repeat: no-repeat;
}

.is-tablet .quotes:before {
	height: 420px;
	width: 440px;
	background-size: 520px auto;
	bottom: -40px;
	left: -40px;
	right: auto;
}

.is-mobile .quotes:before {
	display: none;
}

.quotes h2 {
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 8.4px;
	color: #fff;
	text-transform: uppercase;
	margin-bottom: 60px;
	position: relative;
}

.quotes h2:after {
    content: '';
    position: absolute;
    width: 120px;
    height: 6px;
    background-color: #FF2D07;
    left: 0px;
    bottom: -30px;
}

.is-mobile .quotes h2:after {
	left: 50%;
    height: 4px;
	margin-left: -60px;
	bottom: -15px;
}

.is-tablet .quotes h2 {
	font-size: 20px;
	letter-spacing: 7px;
}

.is-mobile .quotes h2 {
	font-size: 18px;
    letter-spacing: 6.3px;
	padding: 0px 10px;
	text-align: center;
	line-height: 30px;
	margin-bottom: 24px;
}

.quotes-content {
	z-index: 2;
	padding-left: 500px!important;
}

.is-tablet .quotes-content {
	padding-left: 300px!important;
}

.is-mobile .quotes-content {
	padding-left: 20px!important;
}

.quotes-item {
	transition: opacity 300ms ease-in-out;
}

.quotes-item:not(.is-active) {
	position: absolute;
	top: 0px;
	right: 0px;
	left: 0px;
	opacity: 0;
}

.quotes-item.is-active {
	position: relative;
	z-index: 100;
	opacity: 1;
}

.quotes-item p {
	font-weight: 500;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #FFF;
	font-family: 'Poppins', sans-serif;
    margin-bottom: 30px;
}

.quotes-item p >>> b,
.quotes-item p >>> strong {
	font-weight: 700;
}

.quotes-item p >>> em,
.quotes-item p >>> i {
	font-style: italic;
}

.quotes-item p >>> a {
	font-weight: 700;
	border-bottom: 1px dashed #fff;
}

.quotes-item p >>> a:hover {
	border-bottom: 1px solid #fff;
}

.is-device .quotes-item p {
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 30px;
}

.is-mobile .quotes-item p {
	text-align: center;
}

.quotes-item small {
	font-weight: 400;
    font-size: 16px;
    letter-spacing: 3.2px;
    line-height: 30px;
    color: #1A2046;
}

.is-device .quotes-item small {
	font-size: 14px;
	letter-spacing: 5.88px;
}

.is-mobile .quotes-item small {
	text-align: center;
	width: 100%;
	display: block;
}

.quotes-pips {
	display: flex;
	margin-top: 30px;
}

.is-mobile .quotes-pips {
	justify-content: center;
}

.quotes-pips-item {
	border: 1px solid #fff;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 10px;
	transition: all 100ms linear;
	cursor: pointer;
}

.quotes-pips-item.is-active {
	background-color: #fff;
}

</style>
