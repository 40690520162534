<template>

<app-wrapper>

	<app-banner />

	<app-wrapper class="services">

		<app-wrapper :content="true" :text="true" :inverse="true" class="services-content">

			<app-service v-for="(item, index) in $state.page.services" :key="index" :item="item" />

		</app-wrapper>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.services {
	padding: 64px 0px;
}

.is-tablet .services {
	padding: 48px 0px;
}

.is-mobile .services {
	padding: 32px 0px;
}

.services-content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 40px;
}

.is-tablet .services-content {
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.is-mobile .services-content {
	grid-template-columns: 1fr;
	grid-gap: 20px;
}

.services-content >>> .service-body {
	border: 1px solid #eee;
	border-width: 0px 1px 1px 1px;
}

</style>
