<template>

<div class="profile">

	<router-link class="profile-image" :to="{ name: 'Profile', params: { profile: item.slug } }" v-bgimage="item.image"></router-link>

	<div class="profile-name">{{ item.name }}</div>
	<div class="profile-role">{{ item.role }}</div>
	<div class="profile-subrole" v-if="item.subrole">{{ item.subrole }}</div>

	<router-link v-if="button" :to="{ name: 'Profile', params: { profile: item.slug } }" class="profile-button">See profile</router-link>

</div>

</template>

<script>

export default {

	props: ['item', 'button']

}

</script>

<style scoped>

.profile {
	width: 320px;
}

.is-device .profile {
	width: 170px;
}

.profile-image {
	width: 320px;
	height: 320px;
	display: block;
    border-radius: 50%;
	background-position: 50% 50%;
	background-size: contain;
	margin-bottom: 14px;
}

.is-device .profile-image {
	width: 170px;
	height: 170px;
	margin-bottom: 20px;
}

.profile-name {
	font-weight: 700;
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 25px;
    color: #1A2046;
	text-align: center;
}

.is-tablet .profile-name {
	font-size: 18px;
}

.is-mobile .profile-name {
	font-size: 16px;
}

.profile-role {
	font-weight: 400;
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 25px;
    color: #FF2D07;
	text-align: center;
}

.is-mobile .profile-role {
    font-size: 14px;
	line-height: 20px;
}

.profile-subrole {
	font-weight: 500;
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 25px;
    color: #333;
	text-align: center;
}

.is-mobile .profile-subrole {
    font-size: 14px;
	line-height: 20px;
}

.profile-button {
	margin: 0px auto;
	display: block;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 48px;
	border-radius: 24px;
	text-align: center;
    color: #FFF;
	background-color: #485CC7;
	width: 170px;
	height: 48px;
	margin-top: 20px;
}

.is-mobile .profile-button {
	height: 40px;
	line-height: 40px;
	width: 150px;
	font-size: 14px;
}

.profile-button:hover {
	background-color: #3548ae;
}

</style>
