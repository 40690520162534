<template>

<app-wrapper>

	<app-banner :is-small="true" />

	<com-head />

	<com-band />

	<com-news v-if="$state.page.news.length" />

	<app-wrapper :content="true" class="profile-body">

		<app-text :text="$state.page.content" />

		<app-panel title="Practice areas" v-if="$state.page.services.length">

			<div class="profile-services">

				<app-service :is-pill="true" v-for="(item, index) in $state.page.services" :key="index" :item="item" />

			</div>

		</app-panel>

	</app-wrapper>

	<com-timeline v-if="$state.page.timeline.length" />

	<app-quotes v-if="$state.page.quotes_enabled" />

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-head': () => import('./profile/Head'),
		'com-band': () => import('./profile/Band'),
		'com-news': () => import('./profile/News'),
		'com-timeline': () => import('./profile/Timeline'),

	}

}

</script>

<style scoped>

.profile-body {
	padding: 64px 20px!important;
	display: grid;
	align-items: start;
	grid-template-columns: auto 408px;
	grid-gap: 68px;
}

.is-tablet .profile-body {
	padding: 48px 20px 0px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-tablet .profile-services {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr;
}

.is-mobile .profile-body {
	padding: 32px 20px 0px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-mobile .profile-services {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr;
}

</style>
