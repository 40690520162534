<template>

<app-wrapper class="banner-wrapper">

	<app-wrapper class="banner" :class="{'is-small': isSmall}" v-bgimage="$state.page.banner_image">

		<app-wrapper class="banner-content" :content="true" v-if="!window.is.mobile && !isSmall">

			<h1>{{ $state.page.banner_title }}</h1>
			<p v-if="$state.page.banner_subtitle">{{ $state.page.banner_subtitle }}</p>

			<app-text class="banner-text" :text="'<p>' + $state.page.banner_content + '</p>'" v-if="$state.page.banner_content" />

		</app-wrapper>

	</app-wrapper>

	<app-wrapper class="banner-content" :content="true" v-if="window.is.mobile && !isSmall">

		<h1>{{ $state.page.banner_title }}</h1>
		<p v-if="$state.page.banner_subtitle">{{ $state.page.banner_subtitle }}</p>

		<app-text class="banner-text" :text="'<p>' + $state.page.banner_content + '</p>'" v-if="$state.page.banner_content" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {
	
	props: ['isSmall']
}

</script>

<style scoped>

.banner {
	min-height: 450px;
	background-position: 50% 100%;
	background-size: cover;
	background-color: #FEC32D;
	background-repeat: no-repeat;
	padding-top: 167px;
}

.banner.is-small {
	min-height: 325px;
}

.is-tablet .banner,
.is-tablet .banner.is-small {
	min-height: 232px;
	padding-top: 0px;
}

.is-tablet .banner.is-small {
	min-height: 104px;
}

.is-mobile .banner,
.is-mobile .banner.is-small {
	min-height: 160px;
	padding-top: 0px;
}

.is-mobile .banner.is-small {
	min-height: 104px;
}

.banner-content {
	min-height: 283px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 64px 0px;
}

.is-tablet .banner-content {
	min-height: 232px;
	padding: 32px 0px;
}

.is-mobile .banner-content {
	min-height: 160px;
	padding: 32px 0px 0px 0px;
	min-height: 0px;
}

.banner-content h1 {
	font-size: 56px;
	font-weight: 700;
	max-width: 700px;
	color: #fff;
	line-height: 60px;
}

.is-tablet .banner-content h1 {
	font-size: 36px;
	line-height: 40px;
}

.is-mobile .banner-content h1 {
	font-size: 24px;
	color: #1A2046;
	line-height: 32px;
	text-align: center;
}

.banner-content p {
	font-size: 26px;
	color: #45464B;
	line-height: 34px;
	max-width: 600px;
	font-weight: 400;
	margin-top: 32px;
}

.is-tablet .banner-content p {
	font-size: 20px;
	margin-top: 10px;
	line-height: 24px;
	max-width: 360px;
}

.is-mobile .banner-content p {
	font-size: 18px;
	margin-top: 10px;
	text-align: center;
	line-height: 24px;
}

.banner-text {
	margin-top: 32px;
	max-width: 700px;
}

</style>
