<template>

<app-wrapper>

	<app-banner :is-small="true" />

	<app-wrapper :content="true" class="article">

		<com-content />

		<app-wrapper>

			<com-share />

			<app-panel theme="white" title="Legal topics" class="article-tags">

				<app-tag v-for="(item, index) in $state.page.allTags" :key="index" :item="item" />

			</app-panel>

		</app-wrapper>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-share': () => import('./article/Share'),
		'com-content': () => import('./article/Content')

	}

}

</script>

<style scoped>

.article {
	padding: 64px 20px!important;
	display: grid;
	align-items: start;
	grid-template-columns: auto 408px;
	grid-gap: 68px;
}

.is-tablet .article {
	padding: 48px 20px 0px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-tablet .article-tags {
	padding: 20px!important;
	margin-top: 0px!important;
}

.is-mobile .article {
	padding: 32px 20px 0px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-mobile .article-tags {
	padding: 20px!important;
	margin-top: 0px!important;
}

</style>
