<template>

<app-wrapper>

	<app-banner />

	<app-wrapper :content="true" class="about-content">

		<app-text :text="$state.page.content" />

		<div>

			<app-panel title="Practice areas">

				<div class="about-services">
				
					<app-service v-for="(item, index) in $state.page.services" :key="index" :item="item" :is-pill="true" />

				</div>

			</app-panel>

		</div>

	</app-wrapper>

	<app-feature v-if="$state.page.feature_enabled" />
	<app-quotes v-if="$state.page.quotes_enabled" />

</app-wrapper>

</template>

<script>

export default {

	components: {

	}

}

</script>

<style scoped>

.about-content {
	padding: 64px 20px!important;
	display: grid;
	align-items: start;
	grid-template-columns: auto 408px;
	grid-gap: 68px;
}

.is-tablet .about-content {
	padding: 48px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-tablet .about-services {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr;
}

.is-mobile .about-content {
	padding: 32px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-tablet .siblings {
	padding: 20px!important;
	margin-top: 0px!important;
}

.is-mobile .siblings {
	padding: 20px!important;
	margin-top: 0px!important;
}

.siblings-item {
	display: block;
	font-size: 16px;
	line-height: 28px;
	font-weight: 700;
	padding: 14px 68px 14px 20px;
	color: #45464B;
	border-bottom: 1px solid #F5F5F5;
}

.is-desktop .siblings-item:hover {
	background-color: #485CC7;
	color: #fff;
}

.is-mobile .siblings-item {
	font-size: 14px;
	line-height: 20px;
	padding: 14px 48px 14px 0px;
}

.siblings-item i {
	position: absolute;
	top: 21px;
	right: 20px;
	color: #FF2D07;
	font-size: 14px;
}

.is-mobile .siblings-item i {
	right: 0px;
	top: 19px;
}

</style>
