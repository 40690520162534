<template>

<app-wrapper>

	<com-banner />
	<com-team />
	<com-services />
	<app-quotes />
	<com-news />

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-banner': () => import('./home/Banner'),
		'com-team': () => import('./home/Team'),
		'com-services': () => import('./home/Services'),
		'com-news': () => import('./home/News')

	}

}

</script>

<style scoped>

</style>
