<template>

<div class="panel" :data-theme="theme">

	<img :src="$state.page.panel_image" class="panel-image" v-if="$state.page.panel_image && !noimage" />

	<div class="panel-head">{{ title || $state.page.panel_title }}</div>

	<slot></slot>

	<div class="panel-body" v-if="$state.page.panel_enabled && !noimage">

		<p>{{ $state.page.panel_content }}</p>

		<a v-if="$state.page.panel_url" :href="$state.page.panel_url">{{ $state.page.panel_button }}</a>

	</div>

</div>

</template>

<script>

export default {

	props: ['title', 'theme', 'noimage']

}

</script>

<style scoped>

.panel {
	background-color: #485CC7;
	width: 408px;
	border-radius: 10px;
	padding: 20px 30px 40px 30px;
	z-index: 2;
}

.is-tablet .panel {
	margin-top: 48px;
	width: calc(100% + 40px);
	margin-left: -20px;
	border-radius: 0px;
	padding: 20px;
}

.is-mobile .panel {
	margin-top: 32px;
	width: calc(100% + 40px);
	margin-left: -20px;
	border-radius: 0px;
	padding: 20px 20px 40px 20px;
}

.panel-image {
	width: 152px;
	height: auto;
	margin: 40px auto 20px auto;
	display: block;
}

.is-device .panel-image {
	width: 104px;
}

.panel[data-theme="white"] {
	background-color: #fff;
	padding: 20px 0px 20px 0px;
}

.panel-head {
	font-weight: 500;
	font-size: 24px;
	line-height: 40px;
	padding: 30px 0px;
	margin-bottom: 40px;
	text-transform: uppercase;
	letter-spacing: 8.4px;
	color: #fff;
	width: calc(100% + 20px);
	margin-left: -10px;
	text-align: center;
}

.is-device .panel-head {
	font-size: 18px;
	line-height: 30px;
	letter-spacing: 7px;
}

.panel[data-theme="white"] .panel-head {
	color: #1A2046;
}

.panel-head:after {
	content: '';
	position: absolute;
	bottom: -2px;
	background-color: #FF2D07;
	height: 4px;
	left: 50%;
	margin-left: -60px;
	width: 120px;
}

.panel-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.panel-body p {
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;
}

.is-device .panel-body p {
	font-size: 14px;
}

.panel-body a {
	color: #fff;
	margin-top: 40px;
	background-color: #1A2046;
	padding: 0px 20px;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
	font-weight: 500;
	border-radius: 26px;
	text-align: center;
	display: inline-block;
	min-width: 170px;
}

.is-mobile .panel-body a {
	width: 100%;
	margin-top: 32px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
}

</style>
