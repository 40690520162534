<template>

<router-link :to="{name: 'Service', params: { service: item.slug }}" class="service" :class="{'is-pill': pill || isPill}">

	<div class="service-head" v-bgimage="item.background">

		<div class="service-head-logo" v-bgimage="item.image" />

	</div>

	<div class="service-body">

		<div class="service-body-title">{{ item.name }}</div>
		<div class="service-body-text" v-if="!isPill">{{ item.description }}</div>

	</div>

</router-link>

</template>

<script>

export default {

	props: ['item', 'pill', 'isPill']

}

</script>

<style scoped>

.service.is-pill {
	display: flex;
	height: 100px;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;
}

.is-device .service.is-pill {
	height: 96px;
}

.service-head {
	border-radius: 6px 6px 0px 0px;
	background-color: #eee;
	height: 140px;
	z-index: 2;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}	

.service.is-pill .service-head {
	width: 55px;
	height: 100px;
	flex-shrink: 0;
	border-radius: 6px 0px 0px 6px;
}

.service-head-logo {
	width: 112px;
	height: 112px;
	background-size: contain;
	background-position: 50% 50%;
	position: absolute;
	left: 50%;
	margin-left: -56px;
	bottom: -56px;
}

.service.is-pill .service-head-logo {
	width: 52px;
	height: 52px;
	bottom: 50%;
	margin-bottom: -26px;
	margin-left: -26px;
	left: 55px;
}

.is-device .service.is-pill .service-head-logo {
	width: 60px;
	height: 60px;
	margin-left: -30px;
	margin-bottom: -30px;
}

.service-body {
	padding: 76px 20px 60px 20px;
	border-radius: 0px 0px 6px 6px;
	background-color: #fff;
}

.service.is-pill .service-body {
	padding: 20px 20px 20px 50px;
	border-radius: 0px 6px 6px 0px;
	height: 100px;
	flex-grow: 1;
	display: flex;
	align-items: center;
}

.service-body-title {
	font-weight: 700;
    font-size: 22px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #1A2046;
	text-align: center;
    margin-bottom: 40px;
}

.is-device .service-body-title {
	font-size: 16px;
	margin-bottom: 20px;
}

.service.is-pill .service-body-title {
	font-size: 18px;
	line-height: 22px;
	text-align: left;
	margin-bottom: 0px;
}

.is-device .service.is-pill .service-body-title {
	font-size: 16px;
}

.service-body-text {
	font-weight: 500;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 28px;
	text-align: center;
    color: #45464B;
}

.is-device .service-body-text {
	font-size: 14px;
	line-height: 20px;
}

.service.is-pill .service-body-text {
	display: none;
}

.service-body:after {
	background-color: #FF2D07;
	border-radius: 6px 0px 6px 0px;
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 40px;
	height: 40px;
	content: '';
	display: block;
	position: absolute;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAXCAYAAADQpsWBAAAABHNCSVQICAgIfAhkiAAAANhJREFUOE+V04ENwiAQBVCYQDewG+gKTmCcwDiBbqAbuEI30BE6giPoBo0L4P8UGtrCASQXGnqPa7lWG2N2SqknokectdZvzOLQQEzauizCfQ4SvZB4CLbOQiI+XodYlULNxFpoUS0cUQ2coFK4QCUwihxsMLMdvodcHtohtR6nunbtCGEnIlexxXwKNv/mKs0B7VF6pxjgB92mTi8JWCrWJxEsEE4rCyaoFIyoBlgEcMd8mzXZnlKq8UQf3NwECSLwla64eCB+iItUwW/s/9wGCz0AP8js+ANSHnwr5KUZsQAAAABJRU5ErkJggg==');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: 5px 10px;
}

.service.is-pill .service-body:after {
	display: none;
}

</style>
