<template>

<div class="checkbox">

	<div class="checkbox-toggle" :class="{'is-active': value}" v-on:click="$emit('input', !value)"><i class="fa fa-check" /></div>

	<div class="checkbox-text" v-html="text" />

</div>

</template>

<script>

export default {

	props: ['text', 'value']

}

</script>

<style scoped>

.checkbox {
	margin-bottom: 10px;
	display: flex;
}

.checkbox-toggle {
	width: 28px;
	height: 28px;
	border-radius: 6px;
	background-color: #EDEFFA;
	flex-shrink: 0;
	margin-right: 20px;
	cursor: pointer;
	text-align: center;
	line-height: 28px;
	color: #fff;
}

.checkbox-toggle.is-active {
	background-color: #485CC7;
	color: #fff;
}

.checkbox-text {
	font-size: 16px;
	line-height: 26px;
	color: #1A2046;
	flex-grow: 1;
}

.is-device .checkbox-text {
	font-size: 14px;
}

.checkbox-text >>> a {
	font-weight: 700;
}

</style>
