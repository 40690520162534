<template>

<app-wrapper class="logos" v-touch:swipe.right="onBackClick" v-touch:swipe.left="onForwardClick">

	<app-wrapper class="logos-content" :content="true">

		<img :src="logo" class="logos-content-item" v-for="(logo, index) in items" :key="index" :class="{'is-active': index === active, 'is-left': index < active, 'is-right': index > active}" />

		<div class="pips" v-if="window.is.mobile && items.length > 1">
			<div v-for="(item, index) in items" :key="index" class="pips-item" :class="{'is-active': index === active}" v-on:click="onJumpClick(index)" />
		</div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

import mixPips from '@/mixin/pips.js'

export default {

	mixins: [mixPips],

	computed: {

		items: function() {
			
			return this.$state.footer.logos

		}

	}

}

</script>

<style scoped>

.logos {
	padding: 64px 0px;
}

.is-tablet .logos {
	padding: 48px 0px;
}

.is-mobile .logos {
	padding: 32px 0px;
}

.logos-content {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.is-mobile .logos-content {
	flex-direction: column;
    align-items: center;
}

.logos-content-item {
	max-width: 192px;
	margin: 0px 20px;
	transition: opacity 300ms ease-in-out;
}

.is-tablet .logos-content-item {
	max-width: 120px;
	margin: 0px 10px;
}

.is-mobile .logos-content-item:not(.is-active) {
	position: absolute;
	opacity: 0;
}

.is-mobile .logos-content-item.is-active {
	position: relative;
	opacity: 1;
}

.pips {
	display: flex;
	margin-top: 30px;
}

.is-mobile .pips {
	justify-content: center;
}

.pips-item {
	border: 1px solid #485CC7;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 10px;
	transition: all 100ms linear;
	cursor: pointer;
}

.pips-item.is-active {
	background-color: #485CC7;
}

</style>
