<template>

<div class="wrapper" :data-align="align" :data-theme="theme" :class="{'is-content': content || isContext, 'is-inverse': inverse, 'is-padded': padded || isPadded, 'is-left': left, 'is-text': text}">

	<h2 v-if="title" class="wrapper-title">{{ title }}</h2>

	<p v-if="description" class="wrapper-description">{{ description }}</p>

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['content', 'isContext', 'padded', 'isPadded', 'align', 'inverse', 'left', 'text', 'theme', 'title', 'description']

}

</script>

<style scoped>

.wrapper {
	width: 100%;
}

.wrapper[data-theme="blue"] {
	background-color: #485CC7;
}

.wrapper.is-padded {
	padding: 100px 20px;
}

.is-tablet .wrapper.is-padded {
	padding: 48px 20px;
}

.is-mobile .wrapper.is-padded {
	padding: 32px 10px;
}

.wrapper.is-content {
	padding-left: 20px;
	padding-right: 20px;
	max-width: 1280px;
	margin: 0px auto;
}

.wrapper-title {
	margin-bottom: 64px;
}

.is-tablet .wrapper-title {
	margin-bottom: 40px;
}

.is-mobile .wrapper-title {
	margin-bottom: 32px;
}

.wrapper-title,
.wrapper.is-text h2 {
	font-weight: 500;
    font-size: 24px;
    letter-spacing: 8.4px;
    line-height: 30px;
    color: #1A2046;
	position: relative;
	padding-bottom: 32px;
	text-transform: uppercase;
	text-align: center;
}

.is-tablet .wrapper-title,
.is-tablet .wrapper.is-text h2 {
	font-size: 20px;
	padding-bottom: 20px;
	letter-spacing: 7px;
}

.is-mobile .wrapper-title,
.is-mobile .wrapper.is-text h2 {
	font-size: 18px;
	padding-bottom: 20px;
	letter-spacing: 6.3px;
}

.wrapper-title:after,
.wrapper.is-text h2:after {
	content: '';
	position: absolute;
	width: 120px;
	height: 6px;
	background-color: #FF2D07;
	left: 50%;
	margin-left: -60px;
	bottom: -3px;
}

.is-tablet .wrapper-title:after,
.is-tablet .wrapper.is-text h2:after {
	height: 4px;
	bottom: -2px;
}

.is-mobile .wrapper-title:after,
.is-mobile .wrapper.is-text h2:after {
	height: 4px;
	bottom: -2px;
}

.wrapper[data-theme="blue"] .wrapper-title,
.wrapper.is-inverse.is-text h2 {
	color: #fff;
}

.wrapper[data-align="left"] .wrapper-title,
.wrapper.is-left.is-text h2 {
	text-align: left;
}

.wrapper.is-left.is-text h2:after {
	left: 0px;
	margin-left: 0px;
}

.wrapper-description {
	font-size: 16px;
	font-weight: 400;
    color: #1A2046;
	line-height: 28px;
	margin: 30px 0px 64px 0px;
	text-align: center;
}

.is-device .wrapper-description {
	font-size: 14px;
	margin: 30px 0px 30px 0px;
}

.wrapper[data-theme="blue"] .wrapper-description {
	color: #fff;
}

.wrapper[data-align="left"] .wrapper-description,
.wrapper.is-left.is-text p {
	text-align: left;
}

</style>
