<template>

<app-wrapper class="content">

	<app-wrapper :content="true">

		<app-text :text="text || $state.page.content" />

		<slot></slot>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	props: ['text']

}

</script>

<style scoped>

.content {
	padding: 64px 0px;
}

.content >>> h2 {
	font-weight: 500;
    font-size: 24px;
    color: #1A2046;
    margin-bottom: 28px;
	padding-bottom: 28px;
	position: relative;
}

.content >>> h2:after {
	content: '';
	display: block;
	position: absolute;
	left: 0px;
	bottom: -2px;
	background-color: #FF2D07;
	height: 4px;
	width: 120px;
}

.content >>> h3 {
	font-weight: 700;
    font-size: 22px;
    color: #1A2046;
    margin-bottom: 28px;
}

.content >>> p {
	font-weight: 500;
    font-size: 16px;
	line-height: 28px;
    color: #45464B;
    margin-bottom: 28px;
}

.is-inverse .content >>> p {
	color: #fff;
}

.content >>> ul,
.content >>> ol {
    margin-bottom: 28px;
}

.content >>> li {
	font-weight: 500;
    font-size: 16px;
	line-height: 28px;
    color: #45464B;
    margin-bottom: 10px;
	padding-left: 28px;
}

.content >>> li:before {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #FF2D07;
	content: '';
	display: block;
	position: absolute;
	left: 0px;
	top: 10px;
}

.content >>> blockquote {
	margin-bottom: 28px;
}

.content >>> blockquote p {
	font-family: 'Poppins', sans-serif;
	color: #485CC7;
	font-size: 24px;
	line-height: 35px;
}

.content >>> blockquote p strong,
.content >>> blockquote p b {
	font-size: 16px;
	margin-top: 40px;
	font-family: 'Montserrat', sans-serif;
	line-height: 30px;
	position: relative;
	font-weight: 500;
	padding-bottom: 28px;
	display: block;
	color: #1A2046;
}

.content >>> blockquote p strong:after,
.content >>> blockquote p b:after {
	content: '';
	display: block;
	position: absolute;
	left: 0px;
	bottom: -2px;
	background-color: #FF2D07;
	height: 4px;
	width: 120px;
}

.content >>> *:last-child {
	margin-bottom: 0px;
}

</style>
