<template>

<app-wrapper>

	<app-banner />

	<app-content v-if="!$state.page.panel_enabled" />

	<app-wrapper :content="true" class="service-content" v-if="$state.page.panel_enabled">

		<app-text :text="$state.page.content" />

		<app-panel />

	</app-wrapper>

	<app-feature v-if="$state.page.feature_enabled" />

	<app-wrapper v-if="$state.page.cases_enabled" theme="blue">

		<app-wrapper :content="true" :padded="true" :title="$state.page.cases_title" :description="$state.page.cases_content">

			<com-case v-for="(text, index) in $state.page.cases_list" :key="index" :text="text" />

		</app-wrapper>

	</app-wrapper>

	<app-quotes v-if="$state.page.quotes_enabled" />

	<app-extra v-if="$state.page.extra_enabled" />

</app-wrapper>

</template>

<script>

export default {

	components: {
		'com-case': () => import('./service/Case')
	}

}

</script>

<style scoped>

.service-content {
	padding: 64px 20px!important;
	display: grid;
	align-items: start;
	grid-template-columns: auto 408px;
	grid-gap: 68px;
}

.is-tablet .service-content {
	padding: 48px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-mobile .service-content {
	padding: 32px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

</style>
