<template>

<div class="textbox">

	<input type="text" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" v-if="!area" />
	<textarea v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" v-if="area" />

</div>

</template>

<script>

export default {

	props: ['placeholder', 'area', 'value']

}

</script>

<style scoped>

.textbox {
	margin-bottom: 10px;
}

.textbox input,
.textbox textarea {
	width: 100%;
	height: 56px;
	background-color: #EDEFFA;
	border-radius: 6px;
	font-size: 16px;
	color: #1A2046;
	font-weight: 700;
	padding: 0px 20px;
}

.is-device .textbox input,
.is-device .textbox textarea {
	font-size: 14px;
}

.textbox textarea {
	padding: 20px;
	height: 172px;
}

.textbox input::placeholder,
.textbox textarea::placeholder {
	font-weight: 400;
	color: #1A2046;
}

</style>
