<template>

<div class="selectbox">

	<div class="selectbox-value" :class="{'is-empty': !value || !this.options[this.value]}">{{ valueText }}</div>

	<select ref="select" v-on:change="onChange">
		<option value="0" selected>None</option>
		<option v-for="(option, key) in options" :key="key" :value="key">{{ option }}</option>
	</select>

</div>

</template>

<script>

export default {

	props: ['placeholder', 'options', 'value'],

	computed: {

		valueText: function() {

			return (this.value) ? this.options[this.value] || this.placeholder : this.placeholder

		}

	},
	
	methods: {

		onChange: function(e) {

			this.$emit('input', e.target.value)

		}

	}

}

</script>

<style scoped>

.selectbox {
	margin-bottom: 10px;
}

.selectbox-value {
	width: 100%;
	height: 56px;
	line-height: 56px;
	background-color: #EDEFFA;
	border-radius: 6px;
	font-size: 16px;
	color: #1A2046;
	font-weight: 700;
	padding: 0px 20px;
	cursor: pointer;
}

.is-device .selectbox-value {
	font-size: 14px;
}

.selectbox-value.is-empty {
	font-weight: 400;
	color: #1A2046;
}

.selectbox select {
	opacity: 0;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 56px;
	cursor: pointer;
}

</style>
