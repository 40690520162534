<template>

<router-link :to="{name: 'Article', params: { article: item.slug }}" class="article" :class="{'is-slim': isSlim, 'is-imageless': !item.image, 'is-wide': wide || isWide, 'is-long': long || isLong}">

	<div class="article-head" v-if="!isSlim && item.image" v-bgimage="item.image">

		<div class="article-head-label" :data-type="item.type">{{ $constants.newsType[item.type] }}</div>

	</div>

	<div class="article-body">

		<div class="article-head-label" :data-type="item.type" v-if="isSlim || !item.image">{{ $constants.newsType[item.type] }}</div>
		<div class="article-body-date">{{ item.date | formatDate('DD/MM/YYYY') }}</div>
		<div class="article-body-title">{{ item.name }}</div>
		<div class="article-body-text">{{ item.description }}</div>
		<div class="article-body-tags"><router-link v-for="(tag, index) in item.tags" :key="index" :to="{name: 'News', query: { topic: tag.slug }}">{{ tag.name }}</router-link></div>

	</div>

</router-link>

</template>

<script>

export default {

	props: ['item', 'isSlim', 'wide', 'isWide', 'long', 'isLong']

}

</script>

<style scoped>

.article {
	height: 100%;
	display: block;
}

.article.is-wide {
	grid-column: span 2;
}

.is-mobile .article.is-wide {
	grid-column: span 1;
}

.article-head {
	border-radius: 6px 6px 0px 0px;
	background-color: #eee;
	height: 180px;
	z-index: 2;
	background-position: 50% 50%;
	background-size: cover;
}	

.is-desktop .article.is-long .article-head,
.is-tablet .article.is-long .article-head {
	height: 340px;
}

.article-head-label {
	height: 30px;
	position: absolute;
	right: 0px;
	bottom: 0px;
	padding: 0px 30px;
	line-height: 30px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 0.9px;
	font-size: 13px;
}

.article-head-label[data-type="1"] {
	background-color: #485CC7;
}

.article-head-label[data-type="2"] {
	background-color: #00A3AC;
}

.article-head-label[data-type="3"] {
	background-color: #FF2D07;
}

.article-body .article-head-label {
	bottom: auto;
	top: 0px;
	border-radius: 0px 4px 0px 0px;
}

.article-body {
	padding: 20px;
	border-radius: 0px 0px 6px 6px;
	background-color: #fff;
	min-height: 224px;
	border: 1px solid #eee;
	border-top: 0px;
}

.is-device .article-body {
	height: auto;
}

[data-theme="blue"] .article-body {
	border: 0px;
}

.article.is-imageless .article-body {
	border-top: 1px solid #eee;
	border-radius: 6px;
}

.article.is-slim .article-body {
	border-radius: 6px;
	height: auto;
	padding: 30px;
}

.article-body-date {
	font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.5px;
    color: #FF2D07;
    margin-bottom: 4px;
}

.article.is-slim .article-body-date {
	font-size: 13px;
}

.article-body-title {
	font-weight: 700;
    font-size: 22px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #1A2046;
	margin-bottom: 20px;
}

.is-device .article-body-title {
	font-size: 16px;
	line-height: 22px;
	margin-top: 5px;
}

.article-body-text {
	font-weight: 500;
    font-size: 16px;
    max-width: 89%;
    letter-spacing: 0px;
    line-height: 28px;
    color: #45464B;
}

.is-device .article-body-text {
	font-size: 14px;
	line-height: 20px;
}

.article-body:after {
	background-color: #FF2D07;
	position: absolute;
	left: 0px;
	top: 20px;
	width: 4px;
	height: 40px;
	content: '';
	display: block;
	position: absolute;
}

.article.is-slim .article-body:after {
	top: 30px;
}

.article-body-tags {
	margin-top: 15px;
}

.article-body-tags a {
	font-weight: 500;
    font-size: 12px;
    max-width: 89%;
    letter-spacing: 0px;
    line-height: 28px;
    color: #999;
	margin-top: 20px;
	border-right: 1px solid red;
	padding-right: 10px;
	margin-right: 10px;
}

.article-body-tags a:last-child {
	border-right: 0px;
}

.article-body-tags a:hover {
	text-decoration: underline;
}

</style>
