<template>

<div class="submenu" :class="{'is-expanded': expanded}" v-on:mouseenter="onMouseEnter" v-on:mouseleave="onMouseLeave">

	<router-link class="submenu-parent" :to="to" v-if="window.is.desktop">
		<slot></slot>
	</router-link>

	<div class="submenu-parent" v-if="!window.is.desktop" v-on:click="expanded = !expanded">
		<slot></slot>
		<i class="fa fa-chevron-down" v-if="!expanded" />
		<i class="fa fa-chevron-up" v-if="expanded" />
	</div>

	<div class="submenu-list" v-if="expanded">

		<router-link :to="to" v-if="!window.is.desktop" class="submenu-list-item"><slot></slot></router-link>
		<router-link v-for="(item, index) in items" :key="index" class="submenu-list-item" :to="{path: '/' + item.fullURL}">{{ item.name }}</router-link>

	</div>

</div>

</template>

<script>

export default {

	props: ['to', 'value'],

	data: function() {

		return {
			expanded: false
		}

	},

	watch: {

		$path: function() {

			this.expanded = false

		}

	},

	computed: {

		items: function() {

			return this.$_.filter(this.$state.links, function(link) {

				return link.parent === this.value

			}.bind(this))

		}

	},

	methods: {

		onMouseEnter: function() {

			if (this.window.is.desktop) this.expanded = true

		},

		onMouseLeave: function() {

			if (this.window.is.desktop) this.expanded = false

		}

	}

}

</script>

<style scoped>

.is-tablet .submenu.is-expanded,
.is-mobile .submenu.is-expanded {
	padding-bottom: 0px!important;
}

.submenu-parent {
	width: 100%;
	display: block;
}

.submenu-parent i {
	position: absolute;
	right: 0px;
}

.submenu-list {
	position: absolute;
	top: 131px;
	left: 0px;
	background-color: #485CC7;
	display: flex;
	flex-direction: column;
	width: 300px;
}

.is-tablet .submenu-list,
.is-mobile .submenu-list {
	position: relative;
	width: calc(100% + 80px);
	margin-left: -40px;
	margin-top: 20px;
	top: auto;
	left: auto;
	background-color: #fff;
}

.submenu-list-item {
	border-top: 1px solid rgba(255, 255, 255, 0.25);
	padding: 20px;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.is-tablet .submenu-list-item,
.is-mobile .submenu-list-item {
	color: #1A2046;
	letter-spacing: 0px;
	padding: 20px 40px;
	border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.submenu-list-item:hover {
	background-color: rgba(0, 0, 0, 0.15);
}

</style>
