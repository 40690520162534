<template>

<app-wrapper class="feature">

	<app-wrapper class="feature-content" :text="true" :content="true" :inverse="true" :title="$state.page.feature_title">

		<app-text :text="$state.page.feature_content" class="feature-text" />

		<a v-if="$state.page.feature_url" :href="$state.page.feature_url" class="feature-button">{{ $state.page.feature_button }}</a>

	</app-wrapper>

	<div v-if="$state.page.feature_image && !window.is.mobile" class="feature-image" v-bgimage="$state.page.feature_image" />

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.feature {
	background-color: #485CC7;
	padding: 100px 0px;
	margin-bottom: 100px;
	background-image: url(~@/assets/feature.png);
	background-position: calc(50% - 150px) 50%;
	background-size: cover;
}

.feature >>> h2 {
	text-align: left!important;
}

.feature >>> h2:after {
	left: 0px!important;
	margin-left: 0px!important;
}

.is-tablet .feature {
	padding: 48px 0px;
	margin-bottom: 40px;
	background-position: calc(50% - 50px) 50%;
}

.is-mobile .feature {
	padding: 32px 0px;
	margin-bottom: 32px;
	background-image: none;
}

.feature-content {
	padding-right: 785px!important;
}

.is-tablet .feature-content {
	padding-right: 380px!important;
}

.is-mobile .feature-content {
	padding-right: 20px!important;
}

.feature-button {
	margin-top: 64px;
	color: #fff;
	background-color: #1A2046;
	padding: 0px 20px;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
	font-weight: 500;
	border-radius: 26px;
	display: inline-block;
}

.is-mobile .feature-button {
	margin: 32px 0px 0px 0px;
	width: 100%;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	text-align: center;
}

.feature-image {
	width: 785px;
	height: 785px;
	background-position: 50% 100%;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	right: -100px;
	bottom: -100px;
}

.is-tablet .feature-image {
	width: 440px;
	height: 520px;
	background-size: 540px auto;
	bottom: -40px;
}

</style>
