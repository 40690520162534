<template>

<app-wrapper>

	<app-banner />
	
	<app-wrapper :content="true" class="contact">

		<app-wrapper>

			<app-text v-if="!success" :text="$state.page.content" />
			<app-text v-if="success" :text="$state.page.success_complete" />

			<com-form v-on:success="success = true" />

			<app-text v-if="!success" text="<br/><br/><br/><br/><h3>For other issues, including recruitment or management</h3><p>Please email: enq@luqmanithompson.com</p>" />

		</app-wrapper>

		<app-panel title="Contact details">

			<div v-if="$state.footer.email && !window.is.mobile" class="contact-item"><a :href="'mailto:' + $state.footer.email"><i class="fa fa-envelope"></i> <span>{{ $state.footer.email }}</span></a></div>
			<div v-if="$state.footer.email && window.is.mobile" class="contact-item"><a :href="'mailto:' + $state.footer.email"><i class="fa fa-envelope"></i> <span>click here</span></a></div>
			<div v-if="$state.footer.phone" class="contact-item"><a :href="'tel:' + $state.footer.phone"><i class="fa fa-mobile"></i> <span>{{ $state.footer.phone }}</span></a></div>
			<div v-if="$state.footer.fax" class="contact-item"><a :href="'fax:' + $state.footer.fax"><i class="fa fa-fax"></i> <span>{{ $state.footer.fax }}</span></a></div>
			<div v-if="$state.footer.address" class="contact-item"><i class="fa fa-map-marker"></i> <span>{{ $state.footer.address }}</span></div>
			
			<iframe :src="$state.page.map_url" class="contact-map" allowfullscreen />

			<app-text :text="$state.page.map_content" />

		</app-panel>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-form': () => import('./contact/Form')

	},

	data: function() {

		return {
			success: false
		}

	}

}

</script>

<style scoped>

.contact {
	padding: 64px 20px!important;
	display: grid;
	align-items: start;
	grid-template-columns: auto 408px;
	grid-gap: 68px;
}

.is-tablet .contact {
	padding: 48px 20px 0px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-mobile .contact {
	padding: 32px 20px 0px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.contact-map {
	border-radius: 10px;
	width: 100%;
	height: 357px;
	margin: 40px 0px;
}

.is-device .contact-map {
	margin: 20px 0px;
}

.contact-item {
	margin-bottom: 10px;
	font-weight: 400;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 26px;
	display: flex;
	color: #fff;
}

.is-device .contact-item {
	font-size: 14px;
	align-items: center;
}

.contact-item i {
	width: 32px;
	line-height: 23px;
	font-size: 20px;
	background-color: #1A2046;
	width: 44px;
	height: 44px;
	border-radius: 22px;
	text-align: center;
	line-height: 44px;
	flex-shrink: 0;
	margin-right: 20px;
}

.contact-item >>> a:hover span {
	text-decoration: underline;
}

</style>
