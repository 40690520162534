<template>

<app-wrapper>

	<app-banner />

	<app-wrapper :content="true" class="team">

		<app-profile v-for="(item, index) in $state.page.profiles" :key="index" :item="item" :button="true" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.team {
	display: grid;
	padding: 100px 0px;
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: center;
	grid-gap: 100px 40px;
}

.is-tablet .team {
	padding: 48px 0px;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
}

.is-mobile .team {
	padding: 32px 0px;
	grid-template-columns: 1fr;
	grid-gap: 20px;
}

</style>
