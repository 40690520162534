<template>

<app-wrapper class="cta" v-if="active">

	<div class="cta-box">

		<div class="cta-close" v-on:click="active = false" />

		<div class="cta-box-body">

			<app-text align="center" :text="$state.footer.cta_content" />

			<div class="cta-box-buttons">
				<app-button theme="blue" :to="{path: $state.footer.cta_url}" class="cta-button" text="More info" />
				<app-button theme="blue" v-on:click.native="active = false" class="cta-button" text="No thank you" />
			</div>

		</div>

	</div>
	
</app-wrapper>

</template>

<script>

import Cookies from 'js-cookie'

export default {
	
	data: function() {

		return {
			active: false
		}

	},

	created: function() {

		if (Cookies.get(this.$state.footer.cta_id) === undefined && this.window.is.desktop) {

			window.addEventListener('scroll', this.onScroll.bind(this))

		}

	},

	watch: {

		$path: function() {

			this.active = false

		}

	},

	methods: {

		show: function() {

			this.active = true

			window.removeEventListener('scroll', this.onScroll.bind(this))

			Cookies.set(this.$state.footer.cta_id, true, {
				expires: 28
			})

		},

		onScroll: function() {

			if(window.scrollY > 1200 && Cookies.get(this.$state.footer.cta_id) === undefined) this.show()

		}

	}

}

</script>

<style scoped>

.cta {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
}

.cta-close {
	background-color: #fff;
	width: 44px;
	height: 44px;
	position: absolute;
	right: 20px;
	top: 20px;
	border-radius: 50%;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAIVBMVEUAAAD/LQj/LQf/KgX/LQf/LQb/LQf/LAj/LQf/Lgf/LQe2rO5dAAAACnRSTlMAYPAw0FDvQODP+cULUwAAAJBJREFUGNNd0TEOgkAQheHnDbCxsLUwsbLwBhZewMreK3ABOm6xBArmlLDk5Z/AFpvMfn/CJKj9K8+l0yMaxlPM61yYrzHqF9HAca9XgYfnetfAfJMcwA5gB7ADM4GZ4LxxBmEmMDuAmb1k9mXHBHzPAfsQsG9J9ivsZ5j37AywBd5oOvyPWX1lglGvj/J83wsJ8VlzqjgP9wAAAABJRU5ErkJggg==');
	background-size: 15px 15px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.cta-box {
	width: 100%;
	max-width: 998px;
	border-radius: 12px;
	padding: 80px;
	background-image: url(~@/assets/cta.png);
	background-position: 50% 50%;
	background-size: cover;
}

.cta-box-body {
	border-radius: 6px;
	background-color: #fff;
	padding: 40px;
}

.cta-button {
	margin-top: 40px;
}

.cta-box-buttons {
	display: flex;
	justify-content: center;
}

.cta-box-buttons >>> .button {
	margin: 40px 10px 0px 10px;
}

</style>
