import Vue from 'vue'
import Router from 'vue-router'

import pageHome from '@/components/page/Home'
import pageAbout from '@/components/page/About'
import pageServices from '@/components/page/Services'
import pageService from '@/components/page/Service'
import pageNews from '@/components/page/News'
import pageCosts from '@/components/page/Costs'
import pageTeam from '@/components/page/Team'
import pageRequest from '@/components/page/Request'
import pageProfile from '@/components/page/Profile'
import pageArticle from '@/components/page/Article'
import pageContact from '@/components/page/Contact'
import pageGeneric from '@/components/page/Generic'

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.VUE_APP_BASE,
	routes: [
		{ name: 'Home', path: '/', meta: { }, component: pageHome },
		{ name: 'About', path: '/about', meta: { }, component: pageAbout },
		{ name: 'Services', path: '/legal-services', meta: { }, component: pageServices },
		{ name: 'Service', path: '/legal-services/:service', meta: { }, component: pageService },
		{ name: 'Team', path: '/our-lawyers', meta: { }, component: pageTeam },
		{ name: 'Costs', path: '/costs', meta: { }, component: pageCosts },
		{ name: 'Request', path: '/costs/fixedfee-consultation', meta: { }, component: pageRequest },
		{ name: 'RequestOld', path: '/costs/request-consultation', meta: { }, component: pageRequest },
		{ name: 'Profile', path: '/our-lawyers/:profile', meta: { }, component: pageProfile },
		{ name: 'News', path: '/news', meta: { }, component: pageNews },
		{ name: 'Article', path: '/news/:article', meta: { }, component: pageArticle },
		{ name: 'Contact', path: '/contact', meta: { }, component: pageContact },
		{ name: 'Generic', path: '/:page', meta: { }, component: pageGeneric },
		{ name: 'Page', path: '*', meta: { }, component: pageGeneric }
	]
})