<template>

<app-wrapper class="extra">

	<app-wrapper class="extra-content" :text="true" :content="true" :inverse="true">

		<h2>{{ $state.page.extra_title }}</h2>

		<app-text :text="$state.page.extra_content" class="extra-text" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.extra {
	background-color: #485CC7;
	padding: 100px 0px;
	margin-bottom: 100px;
}

.is-tablet .extra {
	padding: 48px 0px;
	margin-bottom: 0px;
}

.is-mobile .extra {
	padding: 32px 0px;
	margin-bottom: 0px;
}

.extra-text {
	padding: 64px 200px 0px 200px;
	text-align: center;
}

.is-tablet .extra-text {
	padding: 48px 0px 0px 0px;
}

.is-mobile .extra-text {
	padding: 32px 0px 0px 0px;
}

</style>
