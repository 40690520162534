export default {

	data: function() {

		return {
			active: 0,
			delay: 10000,
			direction: 'forward'
		}

	},

	created: function() {

		if (this.items.length > 1) {

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)
			
		}

	},

	beforeDestroy: function() {

		clearTimeout(this.timer)

	},

	methods: {

		onAutomateNext: function() {

			if (this.direction === 'forward') {

				this.onForwardClick()

			} else {

				this.onBackClick()

			}

		},

		onBackClick: function() {

			clearTimeout(this.timer)

			this.active = (this.active > 0) ? this.active - 1 : this.items.length - 1

			this.direction = 'back'

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

		},

		onForwardClick: function() {

			clearTimeout(this.timer)

			this.active = (this.active < this.items.length - 1) ? this.active + 1 : 0

			this.direction = 'forward'

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

		},

		onJumpClick: function(index) {

			clearTimeout(this.timer)

			this.active = index 

			this.direction = 'forward'

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

		}

	}

}